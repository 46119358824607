import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "@components/layout"
import Seo from "@components/seo"
import { Zoom } from "react-reveal"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

const EightReasonsPage = ({ data }) => (
  <Layout>
    <Seo
      title="8 Reasons to Choose IT Specialists"
      description={data.site.siteMetadata.description}
    />

    <div className="page-headline">
      <div className="container">
        <h6 className="text-white-50 text-uppercase">Work with Us</h6>
        <h1>8 Reasons to Choose IT Specialists</h1>
      </div>
    </div>
    <div className="page-content bg-light">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-9">
            <div className="text-center">
              <h2 className="display-4">
                We work hard on IT so you don't have to!
              </h2>
              <p className="py-4">
                IT Specialists is your Partner in IT. We are with you each step
                of the way, helping you grow through your technology and not
                only prepare for the unexpected, but prevent it from happening
                in the first place. We provide the best possible service to
                ensure that you have peace of mind about your technology and can
                focus on growing your business instead of focusing on your IT.
                We are here for you when your printer or even your server is
                not.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="page-content">
      <div className="container">
        {data.allSanityWhyChoose.nodes.map((valueprop, i) => {
          const ggimage = getImage(
            valueprop.chooseBanner !== null
              ? valueprop.chooseBanner.asset.gatsbyImageData
              : "https://source.unsplash.com/pencil/joshhild/500x500"
          )
          const convertggimage = convertToBgImage(ggimage)
          return (
            <div className="mb-5 pb-5" key={i}>
              <div className="row justify-content-center align-items-center h-100">
                <div
                  className={`col-lg-7 order-1 ${
                    ++i % 2 !== 0 ? "order-lg-1" : "order-lg-2"
                  }`}
                >
                  <div className="bg-light value-prop h-100">
                    <div className="number-decor">{i}</div>
                    <h6 className="mb-2 text-uppercase text-secondary">
                      Why Work with Us?
                    </h6>
                    <h3 className="mt-0">{valueprop.heading}</h3>
                    <p className="pt-4">{valueprop.description}</p>
                  </div>
                </div>
                <div
                  className={`col-lg-4 order-2 mb-5 mb-lg-0 ${
                    i % 2 !== 0 ? "order-lg-2" : "order-lg-1"
                  }`}
                >
                  <div
                    className={`img-backdrop value-prop-img ${
                      i % 2 !== 0 ? "lines-bg" : "dots-bg"
                    }`}
                  >
                    <BackgroundImage
                      Tag="div"
                      {...convertggimage}
                      style={{ position: "unset" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        })}
        <Zoom>
          <div className="text-center pt-2">
            <Link
              className="btn btn-primary btn-lg"
              role="button"
              to="/solutions"
            >
              Our Solutions
            </Link>
          </div>
        </Zoom>
      </div>
    </div>
  </Layout>
)

export default EightReasonsPage

export const eightReasonsPageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityWhyChoose {
      nodes {
        description
        heading
        chooseBanner {
          asset {
            gatsbyImageData(
              placeholder: BLURRED
              layout: CONSTRAINED
              fit: FILL
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`
